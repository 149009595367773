/* eslint-disable import/no-dynamic-require,no-unused-vars,comma-spacing,max-len */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../components/AsyncComponent';
import PrivateRoute from '../../components/PrivateRoute';

const Routes = () => (
  <Switch>
    <PrivateRoute
      key="SearchList"
      exact
      path="/Manage/SearchList"
      component={asyncComponent(() => import('./SearchList'))}
    />
    <PrivateRoute
      key="BasicDetail"
      exact
      path="/Manage/BasicDetail"
      component={asyncComponent(() => import('./BasicDetail'))}
    />
    <PrivateRoute
      key="CustomerList"
      exact
      path="/Manage/CustomerList"
      component={asyncComponent(() => import('./CustomerList'))}
    />
    <PrivateRoute
      key="CustomerDetail"
      exact
      path="/Manage/CustomerDetail/:id/:agencyName"
      component={asyncComponent(() => import('./CustomerDetail'))}
    />
    <PrivateRoute
      key="TagList"
      exact
      path="/Manage/TagList"
      component={asyncComponent(() => import('./TagList'))}
    />
    <PrivateRoute
      key="BoardList"
      exact
      path="/Manage/BoardList"
      component={asyncComponent(() => import('./BoardList'))}
    />
    <PrivateRoute
      key="VisitAgencyList"
      exact
      path="/Manage/IndustryDatabase/VisitAgency"
      component={asyncComponent(() => import('./IndustryDatabase/VisitAgency'))}
    />
    <PrivateRoute
      key="VisitList"
      exact
      path="/Manage/IndustryDatabase/Visit"
      component={asyncComponent(() => import('./IndustryDatabase/Visit'))}
    />
    <PrivateRoute
      key="VisitDetail"
      exact
      path="/Manage/IndustryDatabase/VisitDetail/:id"
      component={asyncComponent(() => import('./IndustryDatabase/VisitDetail'))}
    />
    <PrivateRoute
      key="EvaluateList"
      exact
      path="/Manage/EvaluateList"
      component={asyncComponent(() => import('./EvaluateList'))}
    />
    <PrivateRoute
      key="CommodityList"
      exact
      path="/Manage/Commodity/CommodityList"
      component={asyncComponent(() => import('./Commodity/CommodityList'))}
    />
    <PrivateRoute
      key="CommodityDetail"
      exact
      path="/Manage/Commodity/CommodityDetail/:id"
      component={asyncComponent(() => import('./Commodity/CommodityDetail'))}
    />
    <PrivateRoute
      key="PriceListRelation"
      exact
      path="/Manage/PriceList/Relation"
      component={asyncComponent(() => import('./PriceList/Relation'))}
    />
    <PrivateRoute
      key="PriceListMakeList"
      exact
      path="/Manage/PriceList/Make"
      component={asyncComponent(() => import('./PriceList/Make'))}
    />
    <PrivateRoute
      key="PermissionCatalogue"
      exact
      path="/Manage/Permission/Catalogue"
      component={asyncComponent(() => import('./Permission/Catalogue/List'))}
    />
    <PrivateRoute
      key="PermissionRole"
      exact
      path="/Manage/Permission/Role"
      component={asyncComponent(() => import('./Permission/Role/List'))}
    />
    <PrivateRoute
      key="PermissionAccount"
      exact
      path="/Manage/Permission/Account"
      component={asyncComponent(() => import('./Permission/Account/List'))}
    />
    <PrivateRoute
      key="HighSeaList"
      exact
      path="/Manage/HighSea/List"
      component={asyncComponent(() => import('./HighSeaList'))}
    />
    <PrivateRoute
      key="HighSeaLimitList"
      exact
      path="/Manage/HighSeaLimit/List"
      component={asyncComponent(() => import('./HighSeaLimitList'))}
    />
    <PrivateRoute
      key="Organization"
      exact
      path="/Manage/Organization"
      component={asyncComponent(() => import('./Organization'))}
    />
    <PrivateRoute
      key="OperationLog"
      exact
      path="/Manage/OperationLog"
      component={asyncComponent(() => import('./OperationLog'))}
    />
    <PrivateRoute
      key="Quotation"
      exact
      path="/Manage/Lead/Quotation"
      component={asyncComponent(() => import('./Quotation'))}
    />
    <PrivateRoute
      key="Service"
      exact
      path="/Manage/Service"
      component={asyncComponent(() => import('./ServiceList'))}
    />
    <PrivateRoute
      key="Service"
      exact
      path="/Manage/Service/Name"
      component={asyncComponent(() => import('./ServiceNameList'))}
    />
    <PrivateRoute
      key="ServiceDetail"
      exact
      path="/Manage/Service/Detail/:id"
      component={asyncComponent(() => import('./ServiceDetail'))}
    />
    <PrivateRoute
      key="AgentList"
      exact
      path="/Manage/AgentList"
      component={asyncComponent(() => import('./AgentList'))}
    />
    <PrivateRoute
      key="AgentDetail"
      exact
      path="/Manage/Agent/Detail/:id"
      component={asyncComponent(() => import('./AgentDetail'))}
    />
    <PrivateRoute
      key="AgentAssociation"
      exact
      path="/Manage/AgentAssociation"
      component={asyncComponent(() => import('./AgentAssociation'))}
    />
    <PrivateRoute
      key="AgentContractAndApproval"
      exact
      path="/Manage/AgentContractAndApproval"
      component={asyncComponent(() => import('./AgentContractAndApproval'))}
    />
    <PrivateRoute
      key="AgentReport"
      exact
      path="/Manage/AgentReport"
      component={asyncComponent(() => import('./AgentReport'))}
    />

    <Redirect from="/Manage/*" to="/404" />
  </Switch>
);

export default Routes;
